import { HTTPError } from "ky";
import { isToday, isYesterday, lightFormat } from "date-fns";
import {
  MARKET_NEWSFLASH_TEMPLATE_SLUG,
  REGULATORY_UPDATES_TEMPLATE_SLUG,
} from "./constants";
import type { DocumentType } from "@/http/api/document/types";
import type { Template } from "./http/api/templates/types";
import type { postSourcePDFType } from "./http/api/sources/types";
import type { WorkspaceTypeLite } from "./http/api/workspaces/types";
import type { GetRegulatoryInventoryData } from "./http/api/regulatory-updates/types";

export const getWorkspacePath = (workspaceId: string, currentPath?: string) => {
  if (currentPath) {
    // if current path is provided, replace the workspace id with the new one
    const split = currentPath.split("/");
    split[3] = workspaceId;
    return split.join("/");
  }
  return `/app/workspaces/${workspaceId}`;
};

export const getInitialWorkspace = (workspaces: WorkspaceTypeLite[]) => {
  const personalWorkspace = workspaces.find(
    (workspace) => workspace.is_personal,
  );

  return personalWorkspace ?? workspaces[0];
};

export const getWorkspaceId = (workspace?: WorkspaceTypeLite) => {
  return workspace ? workspace.id : "";
};

export const throwErrorResponse = ({
  status,
  statusText,
}: {
  status: number;
  statusText: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/only-throw-error
  throw {
    status,
    statusText,
    data: "",
    internal: true,
  };
};

export const getHTTPErrorMessage = async (
  error: unknown,
  errorMessageHandler?: errorMessageHandlerType,
) => {
  let errorMessage = "An unexpected error occurred. Please try again.";
  if (typeof errorMessageHandler === "string") {
    errorMessage = errorMessageHandler;
  }
  if (error instanceof HTTPError) {
    const customErrorMessage = errorMessageHandler?.[error.response.status];
    if (customErrorMessage) {
      errorMessage = customErrorMessage;
    } else if (error.response.status === 500) {
      errorMessage =
        "We're having technical difficulties, please try again later.";
    } else {
      try {
        const errorResponse = await error.response.json<{
          detail: string;
        }>();
        errorMessage = errorResponse.detail;
      } catch (e: unknown) {
        // ignore
        console.log(e);
      }
    }
  } else if (typeof error === "string") {
    errorMessage = error;
  }
  return errorMessage;
};

type errorMessageHandlerType = string | Record<number, string>;

export function replaceLastPathWith(replacement: string) {
  return window.location.pathname.replace(/\/[^/]+$/, `/${replacement}`);
}

export function parseDate(date: string, isEuropeanFormat = true) {
  try {
    const parsedDate = new Date(date);
    if (isToday(parsedDate)) return "Today";
    if (isYesterday(parsedDate)) return "Yesterday";
    return lightFormat(
      parsedDate,
      isEuropeanFormat ? "dd.MM.yyyy" : "MM/dd/yyyy",
    );
  } catch (e) {
    console.log(e);
    return "Not a valid date";
  }
}

export const getFilteredData = ({
  data,
  route,
}: {
  data: DocumentType[];
  route: "documents" | "reports";
}) => {
  const reportTemplateSlugs = [
    MARKET_NEWSFLASH_TEMPLATE_SLUG,
    REGULATORY_UPDATES_TEMPLATE_SLUG,
  ];

  const filteredData = data.filter((doc) => {
    if (route === "documents") {
      // Filter out reports from the data
      return !reportTemplateSlugs.includes(doc.template_slug);
    }
    // Filter out non-reports from the data
    return reportTemplateSlugs.includes(doc.template_slug);
  });
  return filteredData;
};

export const hasUserAccessToNewsflash = (templates: Template[]) => {
  return templates.some(
    (template: Template) => template.slug === MARKET_NEWSFLASH_TEMPLATE_SLUG,
  );
};

export function getSourceKey(source: postSourcePDFType | null) {
  if (!source) return "";
  return `${String(source.file_hash)}-${String(source.page_no)}`;
}

export function downloadBlob({
  blob,
  type,
  fileName,
}: {
  blob: Blob;
  type: "docx" | "pdf";
  fileName: string;
}) {
  const mimeType =
    type === "docx"
      ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      : "application/pdf";
  const file = window.URL.createObjectURL(new Blob([blob], { type: mimeType }));

  const link = window.document.createElement("a");
  link.href = file;
  link.download = fileName;
  link.style.display = "none";

  window.document.body.append(link);
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(file);
    link.remove();
  }, 1000);
}

export function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const getDurationTimeString = (totalSeconds: number) => {
  const originalTotalSeconds = totalSeconds;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  return `${hours ? `${hours.toString()}hr${hours > 1 ? "s" : ""} ` : ""}${minutes && originalTotalSeconds >= 300 ? `${minutes.toString()}min` : originalTotalSeconds < 300 ? "<5min" : ""}`;
};

export const checkEventTargetElement = (
  target: EventTarget,
  element: string,
) => {
  return (target as HTMLElement).tagName.toLowerCase().includes(element);
};

export const getUsernameFromEmail = (
  email: string,
  props: {
    capitalize?: boolean;
  } = { capitalize: false },
) => {
  const [name] = email.split("@");
  if (props.capitalize) {
    return name
      ?.split(".")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return name;
};

export const convertToDate = (dateString: string) => {
  //  Convert a "dd.mm.yyyy" string into a Date object
  const d = dateString.split(".");
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
  return dat;
};

export const formatInventoryItemsQuery = (
  request: GetRegulatoryInventoryData,
) => {
  const { date_from, date_to, include_irrelevant } = request;

  let query = ``;
  if (date_from) {
    query += `date_from=${date_from}&`;
  }
  if (date_to) {
    query += `date_to=${date_to}&`;
  }
  query += `include_irrelevant=${String(include_irrelevant)}`;
  return query;
};
