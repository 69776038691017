import HttpClient from "@/http/httpClient";
import { HTTPError } from "ky";
import { ERROR_LOCKED_FILE } from "@/http/constants";
import type {
  DocumentType,
  PostDocumentCopyRequestType,
  PostDocumentQuestionnaireResponseType,
  PostDocumentRequestType,
  PostDocumentResponseType,
  PostFilesRequestBody,
  PutDocumentRequestType,
  PutDocumentResponseType,
  SingleDocumentType,
} from "./types";
import { throwErrorResponse } from "@/helpers";

interface getDocumentsResponseType {
  documents: DocumentType[];
}

export async function getDocuments() {
  return HttpClient.request().get<getDocumentsResponseType>("documents");
}

type getDocumentResponseType = SingleDocumentType;

export async function getDocument(
  documentId: string,
  { signal }: { signal?: AbortSignal },
) {
  return HttpClient.request()
    .get<getDocumentResponseType>(`documents/${documentId}`, { signal })
    .catch(async (error: unknown) => {
      if (error instanceof HTTPError) {
        if (error.response.status === 400) {
          const errorResponse = await error.response.json<{
            detail: string;
          }>();
          if (errorResponse.detail === ERROR_LOCKED_FILE) {
            throw new Error(ERROR_LOCKED_FILE);
          }
        } else if (error.response.status === 404) {
          return throwErrorResponse({
            status: 404,
            statusText: "Not Found",
          });
        }
      }

      throw new Error((error as string) + "");
    });
}

export async function deleteDocument(id: string) {
  return HttpClient.request().delete(`documents/${id}`);
}

export function closeDocument(documentId: string) {
  void HttpClient.request().get(`documents/${documentId}/close`);
}

export async function postDocument(document: PostDocumentRequestType) {
  return HttpClient.request().post<PostDocumentResponseType>("documents", {
    json: document,
  });
}
export async function putDocument(
  id: string,
  document: Partial<PutDocumentRequestType>,
) {
  return HttpClient.request().put<PutDocumentResponseType>(`documents/${id}`, {
    json: document,
  });
}

export async function getDocumentSources(documentId: string) {
  return HttpClient.request().get(`documents/${documentId}/sources`);
}

export async function postDocumentExport({
  documentId,
  exportType,
}: PostFilesRequestBody) {
  return await HttpClient.request()
    .ky()(`documents/${documentId}.${exportType}`, {
      method: "POST",
    })
    .blob();
}

export function postDocumentExportGoogleDocs({
  documentId,
}: Pick<PostFilesRequestBody, "documentId">) {
  return HttpClient.request().post<{ link: string }>(
    `documents/${documentId}/google-docs`,
  );
}

export async function postDocumentQuestionnaire(file: File) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return await HttpClient.request().post<PostDocumentQuestionnaireResponseType>(
    "documents/questionnaire",
    {
      body: formData,
      headers: {},
    },
  );
}

export async function postDocumentCopy({
  documentId,
  workspaceId,
  name,
}: PostDocumentCopyRequestType) {
  return HttpClient.request().post<PostDocumentResponseType>(
    `documents/${documentId}/copy`,
    {
      json: { workspace_id: workspaceId, name },
    },
  );
}

export async function postCreateRegulatoryNewsflash(regUpdateId: string) {
  return HttpClient.request().post<PostDocumentResponseType>(
    `documents/regulatory-newsflash`,
    {
      json: { reg_update_ids: [regUpdateId] },
    },
  );
}
